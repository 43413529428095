<template>
    <modal :id="id" title="Delete Request">
        <template #default>
            <p>Are you sure you want to delete this author request?</p>
            <p>This action cannot be undone.</p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="remove"
            >
                Delete
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import type { AuthorRequest } from '@/types/AuthorRequest';

@Component({
    components: {
        Modal
    }
})
export default class AuthorRequestDelete extends Vue {
    id = 'author-request-delete';

    @InjectReactive({
        from: 'request',
        default() {
            return {
                id: 0
            };
        }
    })
    request!: Partial<AuthorRequest>;

    remove() {
        if (this.request.id) {
            return this.$http
                .delete(`/author_requests/delete/${this.request.id}`)
                .then(() => {
                    this.$store.dispatch(
                        'notification/success',
                        'Request deleted'
                    );
                })
                .catch(error => {
                    if (!error.isIntercepted) {
                        this.$store.dispatch(
                            'notification/error',
                            'Unable to delete request. Please try again later or contact support.'
                        );
                    }
                })
                .finally(() => {
                    this.close();
                    // ask parent to reload
                    this.$emit('update');
                });
        }
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
