<template>
    <v-container v-if="isBusy && $vuetify.breakpoint.mdAndUp">
        <author-requests-list-header
            v-if="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
        />
        <author-request-row
            v-for="request in requests.length || 10"
            :key="'request-row-' + request"
            loading
        />
    </v-container>
    <v-container v-else-if="isBusy">
        <author-request-card
            v-for="i in requests.length || 10"
            :key="i"
            class="mb-2"
            loading
        />
    </v-container>
    <v-container v-else-if="hasData && $vuetify.breakpoint.mdAndUp">
        <author-requests-list-header />
        <author-request-row
            v-for="(request, index) in requests"
            :key="'request-row-' + request.id + '-' + index"
            :request="request"
            :action="getAction(request)"
            @show-comments="showComments"
            @activate="setActiveItem"
        />

        <assignment-comments
            :assignment-id="showCommentsForRequestId"
            @close="hideComments"
            @update="update"
        />

        <author-request-dialogs v-on="$listeners" />
    </v-container>
    <v-container v-else-if="hasData" class="px-0">
        <author-request-card
            v-for="(request, index) in requests"
            :key="'request-card-' + request.id + '-' + index"
            :request="request"
            class="mb-2"
            :action="getAction(request)"
            @activate="setActiveItem"
            @show-comments="showComments"
        />

        <assignment-comments
            :assignment-id="showCommentsForRequestId"
            @close="hideComments"
            @update="update"
        />
    </v-container>
    <a-message-bar v-else>No requests submitted yet</a-message-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive } from '@/utils/decorators';
import { mapGetters } from 'vuex';

import { AssignmentComments } from '@/components/Authoring/AssignmentComments';

import { AuthorRequestDialogs } from '@/components/Authoring/AuthorRequestActions/AuthorRequestDialogs';

import { AuthorRequestCard } from '@/components/Authoring/AuthorRequestCard';
import { AMessageBar } from '@/components/AMessageBar';

import AuthorRequestActionsFactory from '@/components/Authoring/AuthorRequestActions/AuthorRequestActionsFactory';

import type { AuthorRequest } from '@/types/AuthorRequest';

import AuthorRequestsListHeader from './AuthorRequestsListHeader.vue';
import AuthorRequestRow from './AuthorRequestRow.vue';

const AuthorRequestsListProps = Vue.extend({
    name: 'AuthorRequestsList',
    props: {
        requests: {
            type: Array as PropType<Partial<AuthorRequest>[]>,
            default(): AuthorRequest[] {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        updating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AuthorRequestsListHeader,
        AuthorRequestRow,
        AssignmentComments,
        AuthorRequestDialogs,
        AuthorRequestCard,
        AMessageBar
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AuthorRequestsList extends AuthorRequestsListProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    showCommentsForRequestId = 0;
    // Action dialogs inject that
    @ProvideReactive() request: Partial<AuthorRequest> | null = null;

    get hasData() {
        return Boolean(this.requests.length);
    }

    get isBusy() {
        return this.loading || this.updating;
    }

    showComments(id: number) {
        this.showCommentsForRequestId = id;
    }

    hideComments() {
        this.showCommentsForRequestId = 0;
    }

    setActiveItem(request: Partial<AuthorRequest>) {
        this.request = request;
    }

    getAction(request: Partial<AuthorRequest>) {
        const AuthoringActions = new AuthorRequestActionsFactory(
            request,
            this.isEditor,
            this.isAuthor
        );

        const actions = AuthoringActions.getActions();

        const editAction = actions.find(action =>
            ['write', 'edit'].includes(action.action)
        );

        const commentsAction = actions.find(
            action => action.action === 'review'
        );

        return editAction || commentsAction;
    }

    update() {
        this.$emit('update', true);
    }
}
</script>
