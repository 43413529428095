import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[(_vm.$vuetify.breakpoint.mdAndUp)?_c('welcome-banner',{staticClass:"my-4"}):_vm._e(),(_vm.isReseller)?_c('announcements-client-selector',{staticClass:"px-0",attrs:{"clients":_vm.clients,"loading":_vm.isLoading}}):_vm._e(),_c(VContainer,{staticClass:"px-0"},[_c(VRow,{staticClass:"justify-space-between"},[_c(VCol,{attrs:{"cols":"8","md":"6"}},[_c('div',{staticClass:"text-h6 text-md-h5 font-weight-bold"},[_vm._v(" Author Requests ")])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"primary",attrs:{"title":"New Request","to":_vm.newRequestLink,"small":_vm.$vuetify.breakpoint.smAndDown}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"small":""}},[_vm._v(" plus ")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'New Request' : null)+" ")],1)],1)],1)],1),_c('author-requests-list',{staticClass:"px-0",attrs:{"requests":_vm.requests,"loading":_vm.isLoading,"updating":_vm.isUpdating},on:{"update":_vm.update}}),_c('paging',{staticClass:"main-background px-0",attrs:{"total":_vm.total,"default-per-page":_vm.perPage,"loading":_vm.isUpdating}}),_c('training-section',{staticClass:"mt-10"}),_c('press-cable-user-welcome',{staticClass:"mt-10"}),(_vm.canSeeTrainingLink && !_vm.isLoading)?_c('training-link',{staticClass:"mt-4"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }