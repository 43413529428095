<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-2 white pt-3 pb-3">
        <v-col cols="1" class="pa-0 pl-3 ma-0">
            <v-avatar rounded color="main-background">
                <v-icon color="grey lighten-2" large>image</v-icon>
            </v-avatar>
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(45, 75) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 55) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(30, 45) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(40, 65) + '%'"
            />
        </v-col>
        <v-col cols="1" class="pl-2 pr-0 pt-4 py-0 ma-auto" />
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-2 align-center"
            :class="{
                white: !hover && !isActive,
                'concrete-solid': hover || isActive,
                'elevation-2': hasAction && (hover || isActive),
                'px-2': $vuetify.breakpoint.mdAndDown,
                'with-action': hasAction
            }"
            @click="onAction"
        >
            <v-col cols="1" :class="{ 'px-0': $vuetify.breakpoint.mdAndDown }">
                <author-request-media :request="request" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !isNameDefined,
                    'font-weight-medium': isNameDefined
                }"
            >
                <text-truncated :text="name" />
            </v-col>
            <v-col
                cols="2"
                class="pl-2 pr-0 ma-auto"
                :class="{
                    'cell-disabled': !isCategoryDefined
                }"
            >
                <text-truncated :text="category" />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <author-request-type-chip
                    :request="request"
                    :assignment="request.latest_assignment"
                />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <status-chip :status="request.status_string" />
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto">
                <text-truncated
                    :text="formatDate(request.requested_time)"
                    :tooltip="formatDateTime(request.requested_time)"
                />
            </v-col>
            <v-col cols="1" class="pl-2 pr-0 py-0 ma-auto" align="right">
                <author-request-row-actions
                    :request="request"
                    @open="markRowActive"
                    @show-comments="showComments"
                />
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import {
    isTextSelectionEvent,
    formatDate,
    formatDateTime,
    randomBetween
} from '@/utils/helpers';

import { TextTruncated } from '@/components/TextTruncated';
import { StatusChip } from '@/components/StatusChip';
import { AuthorRequestMedia } from '@/components/Authoring/AuthorRequestMedia';
import AuthorRequestTypeChip from './AuthorRequestTypeChip.vue';
import AuthorRequestRowActions from './AuthorRequestRowActions.vue';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { RequestAction } from '@/components/Authoring/AuthorRequestActions/AuthorRequestActionsFactory';

const AuthorRequestRowProps = Vue.extend({
    name: 'AuthorRequestRow',
    props: {
        request: {
            type: Object as PropType<AuthorRequest>,
            default(): AuthorRequest | null {
                return null;
            }
        },
        action: {
            type: Object as PropType<RequestAction>,
            default(): RequestAction | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AuthorRequestRowActions,
        AuthorRequestTypeChip,
        AuthorRequestMedia,
        TextTruncated,
        StatusChip
    }
})
export default class AuthorRequestRow extends AuthorRequestRowProps {
    isActive = false;

    get name() {
        return this.request?.company?.name || 'No Company Selected';
    }

    get category() {
        return this.request?.category?.name || 'No Category Selected';
    }

    get isNameDefined() {
        return this.request?.company?.name;
    }

    get isCategoryDefined() {
        return this.request?.category?.name;
    }

    get hasAction() {
        return Boolean(this.action);
    }

    formatDate = formatDate;
    formatDateTime = formatDateTime;
    randomBetween = randomBetween;

    markRowActive(isActive: boolean) {
        this.isActive = isActive;

        this.$emit('activate', this.request);
    }

    showComments(id: number) {
        this.$emit('show-comments', id);
    }

    onAction(e: Event) {
        if (this.hasAction && !isTextSelectionEvent(e)) {
            const result = this.action.callback(this, this.request);

            if (result) {
                if (this.action.link) {
                    window.location.href = result;
                }

                if (this.action.route) {
                    this.$router.push(result);
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';
</style>
