<template>
    <v-row v-if="loading" class="ma-0">
        <v-col cols="1" />
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="30%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="40%" height="20" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 pt-4 pb-2">
            <v-skeleton-loader type="text" width="50%" height="20" />
        </v-col>
        <v-col cols="1" />
    </v-row>
    <v-row v-else class="caption text-uppercase ma-0">
        <v-col cols="1" />
        <v-col cols="2" class="pl-2 pr-0">Company</v-col>
        <v-col cols="2" class="pl-2 pr-0">Category</v-col>
        <v-col cols="2" class="pl-2 pr-0">Type</v-col>
        <v-col cols="2" class="pl-2 pr-0">
            Status
            <a-filterable-column :filters="statusFilters" />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0">Submitted</v-col>
        <v-col cols="1" />
    </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AFilterableColumn, Filter } from '@/components/AList';

const AuthorRequestsListHeaderProps = Vue.extend({
    name: 'AuthorRequestsListHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AFilterableColumn
    }
})
export default class AuthorRequestsListHeader extends AuthorRequestsListHeaderProps {
    statusFilters: Filter[] = [
        {
            label: 'Active',
            queryKey: 'active'
        },
        {
            label: 'Cancelled',
            queryKey: 'rejected_time'
        },
        {
            label: 'Draft',
            queryKey: 'draft'
        },
        {
            label: 'Fulfilled',
            queryKey: 'fulfilled'
        },
        {
            label: 'Paused',
            queryKey: 'paused_at'
        }
    ];
}
</script>
