import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VRow,{staticClass:"a-list-row ma-0 mb-2 white pt-3 pb-3"},[_c(VCol,{staticClass:"pa-0 pl-3 ma-0",attrs:{"cols":"1"}},[_c(VAvatar,{attrs:{"rounded":"","color":"main-background"}},[_c(VIcon,{attrs:{"color":"grey lighten-2","large":""}},[_vm._v("image")])],1)],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(45, 75) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(35, 55) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(20, 35) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(30, 45) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 ma-auto",attrs:{"cols":"2"}},[_c(VSkeletonLoader,{attrs:{"type":"text","width":_vm.randomBetween(40, 65) + '%'}})],1),_c(VCol,{staticClass:"pl-2 pr-0 pt-4 py-0 ma-auto",attrs:{"cols":"1"}})],1):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VRow,{staticClass:"a-list-row ma-0 mb-2 align-center",class:{
            white: !hover && !_vm.isActive,
            'concrete-solid': hover || _vm.isActive,
            'elevation-2': _vm.hasAction && (hover || _vm.isActive),
            'px-2': _vm.$vuetify.breakpoint.mdAndDown,
            'with-action': _vm.hasAction
        },on:{"click":_vm.onAction}},[_c(VCol,{class:{ 'px-0': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"cols":"1"}},[_c('author-request-media',{attrs:{"request":_vm.request}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.isNameDefined,
                'font-weight-medium': _vm.isNameDefined
            },attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.name}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",class:{
                'cell-disabled': !_vm.isCategoryDefined
            },attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.category}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_c('author-request-type-chip',{attrs:{"request":_vm.request,"assignment":_vm.request.latest_assignment}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_c('status-chip',{attrs:{"status":_vm.request.status_string}})],1),_c(VCol,{staticClass:"pl-2 pr-0 ma-auto",attrs:{"cols":"2"}},[_c('text-truncated',{attrs:{"text":_vm.formatDate(_vm.request.requested_time),"tooltip":_vm.formatDateTime(_vm.request.requested_time)}})],1),_c(VCol,{staticClass:"pl-2 pr-0 py-0 ma-auto",attrs:{"cols":"1","align":"right"}},[_c('author-request-row-actions',{attrs:{"request":_vm.request},on:{"open":_vm.markRowActive,"show-comments":_vm.showComments}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }