<template>
    <v-container>
        <welcome-banner v-if="$vuetify.breakpoint.mdAndUp" class="my-4" />

        <announcements-client-selector
            v-if="isReseller"
            :clients="clients"
            :loading="isLoading"
            class="px-0"
        />

        <v-container class="px-0">
            <v-row class="justify-space-between">
                <v-col cols="8" md="6">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        Author Requests
                    </div>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        class="primary"
                        title="New Request"
                        :to="newRequestLink"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon :left="$vuetify.breakpoint.mdAndUp" small>
                            plus
                        </v-icon>
                        {{ $vuetify.breakpoint.mdAndUp ? 'New Request' : null }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <author-requests-list
            :requests="requests"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            class="main-background px-0"
        />

        <training-section class="mt-10" />

        <press-cable-user-welcome class="mt-10" />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { AnnouncementsClientSelector } from '@/components/AnnouncementsClientSelector';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { Paging } from '@/components/Paging';
import { AuthorRequestsList } from '@/components/Authoring/AuthorRequestsList';
import { TrainingSection, TrainingLink } from '@/components/Training';
import { PressCableUserWelcome } from '@/components/PressCableUserWelcome';

import { ListPaged, Training, UserId } from '@/mixins';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { ClientUser } from '@/types/User';

@Component({
    components: {
        WelcomeBanner,
        AnnouncementsClientSelector,
        AuthorRequestsList,
        Paging,
        TrainingSection,
        TrainingLink,
        PressCableUserWelcome
    },
    computed: {
        ...mapState('clients', ['clients']),
        ...mapGetters('user', ['isReseller', 'isEditor'])
    }
})
export default class AuthorRequests extends mixins(
    ListPaged,
    Training,
    UserId
) {
    isReseller!: boolean;
    isEditor!: boolean;
    clients!: ClientUser[];

    requests: AuthorRequest[] = [];

    endpoint = '/author_requests';

    get queryParams() {
        return {
            user_id: this.userId,
            limit: this.perPage,
            page: 1,
            sort: 'priority',
            asc: 1,
            active: void 0,
            rejected_time: void 0,
            draft: void 0,
            fulfilled: void 0,
            paused_at: void 0
        };
    }

    get newRequestLink() {
        return ['/author-requests/create', this.activeUserId]
            .filter(Boolean)
            .join('?user_id=');
    }

    onData(data: {
        authorRequests: AuthorRequest[];
        clients: Record<string, string>;
        length: number;
    }) {
        this.requests = data.authorRequests;
        this.total = data.length;

        this.$store.dispatch('clients/set', data.clients);
    }
}
</script>
