<template>
    <span>
        <author-request-delete @update="update" />
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';

import AuthorRequestDelete from './AuthorRequestDelete.vue';

import type { AuthorRequest } from '@/types/AuthorRequest';

type ActionPayload = {
    action: string;
    data?: Record<string, string | number | boolean>;
};

@Component({
    components: {
        AuthorRequestDelete
    }
})
export default class AuthorRequestDialogs extends Vue {
    @InjectReactive({
        from: 'request',
        default() {
            return {
                id: 0
            };
        }
    })
    request!: Partial<AuthorRequest>;

    confirm(payload: ActionPayload) {
        this.applyAction(payload).then(data => {
            if (data) {
                if (data.redirect) {
                    this.redirect(data.redirect);
                } else {
                    if (data.error && data.message) {
                        this.$store.dispatch(
                            'notification/error',
                            data.message
                        );
                    } else {
                        this.$store.dispatch(
                            'notification/success',
                            'Request updated'
                        );
                        this.update();
                    }
                }
            }
        });
    }

    redirect(redirect: string) {
        if (redirect) {
            // let parents know about redirect
            this.$emit('redirect', redirect);
        }
    }

    async applyAction({ action, data = {} }: ActionPayload) {
        return this.$http
            .post(`/author_requests/${action}/${this.request.id}`, data)
            .then(({ data: actionData }) => actionData)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to update request. Please try again later or contact support.'
                    );
                }
            });
    }

    update() {
        // ask parent to reload
        this.$emit('update', true);
    }
}
</script>
