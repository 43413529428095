<template>
    <v-container>
        <template v-if="$vuetify.breakpoint.mdAndUp">
            <amp-banner v-if="isEditor || isAuthor" class="my-4" />
            <welcome-banner v-else class="my-4" />
        </template>

        <announcements-client-selector
            v-if="isReseller"
            :clients="clients"
            :loading="isLoading"
            class="px-0"
        />

        <v-container class="px-0">
            <v-row class="justify-space-between">
                <v-col cols="12" md="auto">
                    <div class="text-h6 text-md-h5 font-weight-bold">
                        My Assignments
                    </div>
                </v-col>
                <v-col cols="12" md="auto" class="d-flex">
                    <v-switch
                        v-model="showLimitedDistribution"
                        label="Selective Distribution"
                        :disabled="isLoading"
                        inset
                        hide-details
                        class="mt-0"
                    />
                </v-col>
            </v-row>
        </v-container>

        <author-request-pool-section
            :requests="requests"
            :loading="isLoading"
            :updating="isUpdating"
            class="px-0 pt-0"
            @update="update"
        />

        <paging
            :total="total"
            :default-per-page="perPage"
            :loading="isUpdating"
            size="10"
            class="main-background px-0"
        />

        <training-section class="mt-10" />

        <press-cable-user-welcome class="mt-10" />

        <training-link v-if="canSeeTrainingLink && !isLoading" class="mt-4" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import { ListPaged, Training, UserId } from '@/mixins';
import { ProvideReactive } from '@/utils/decorators';

import { AmpBanner } from '@/components/AmpBanner';
import { WelcomeBanner } from '@/components/WelcomeBanner';
import { AnnouncementsClientSelector } from '@/components/AnnouncementsClientSelector';
import { Paging } from '@/components/Paging';
import { TrainingSection, TrainingLink } from '@/components/Training';
import { PressCableUserWelcome } from '@/components/PressCableUserWelcome';

import { AuthorRequestPoolSection } from '../sections';

import type { AuthorRequest } from '@/types/AuthorRequest';
import type { ClientUser } from '@/types/User';

@Component<AuthorAssignments>({
    components: {
        AmpBanner,
        AnnouncementsClientSelector,
        AuthorRequestPoolSection,
        Paging,
        TrainingSection,
        TrainingLink,
        PressCableUserWelcome,
        WelcomeBanner
    },
    computed: {
        ...mapState('clients', ['clients']),
        ...mapGetters('user', ['isEditor', 'isReseller'])
    }
})
export default class AuthorAssignments extends mixins(
    ListPaged,
    Training,
    UserId
) {
    isEditor!: boolean;
    isReseller!: boolean;
    clients!: ClientUser[];

    @ProvideReactive('hideAuthor')
    get hideAuthorProvider() {
        return this.isAuthor;
    }

    endpoint = '/author_requests';

    requests: AuthorRequest[] = [];

    get queryParams() {
        return {
            user_id: this.userId,
            limit: 10,
            page: 1,
            sort: 'priority',
            asc: 1,
            showLimitedDistribution: 0
        };
    }

    get showLimitedDistribution() {
        return this.$route.query.showLimitedDistribution === '1';
    }

    set showLimitedDistribution(showLimitedDistribution: boolean) {
        this.$router.push({
            path: this.$route.path,
            query: {
                ...this.$route.query,
                showLimitedDistribution: showLimitedDistribution ? '1' : void 0
            }
        });
    }

    onData(data: {
        authorRequests: AuthorRequest[];
        clients: Record<string, string>;
        length: number;
    }) {
        this.requests = data.authorRequests;
        this.total = data.length;

        this.$store.dispatch('clients/set', data.clients);
    }
}
</script>
