<template>
    <author-assignments v-if="$can('view', 'AuthorAssigmentsList')" />
    <client-author-requests v-else />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import AuthorAssignments from './AuthorAssignments.vue';
import ClientAuthorRequests from './ClientAuthorRequests.vue';

@Component({
    components: {
        AuthorAssignments,
        ClientAuthorRequests
    }
})
export default class List extends Vue {}
</script>
